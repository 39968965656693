<template>
   <div class="obi-logo">
      <ObiImage :src="logoUrl" />
   </div>
</template>

<script>
import logoUrl from '@/assets/images/obifi-white-logo.png';

export default {
   name: 'ObiLogo',
   props: {},
   data() {
      return {
         logoUrl,
      };
   },
};
</script>
<style lang="scss" scoped>
.obi-logo {
   img {
      max-height: 35px;
   }
}
</style>
